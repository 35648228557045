.container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;
}

.overlay-side-elements {
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 0;
	right: 0;
	margin: 20px;
	align-items: center;
	z-index: 2;
}

.wiggle-icon:hover {
	animation: shake 0.3s;
}

@keyframes shake {
	0% { transform: rotate(0deg); }
	25% { transform: rotate(-7deg); }
	75% { transform: rotate(7deg); }
	100% { transform: rotate(0deg); }
}