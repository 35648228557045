@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

.name {
	font-weight: 500;
	font-size: 4rem;
	text-align: center;
	margin: 10px 10px 0px 10px;
	user-select: none;
}

.links-container {
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
}

.icon {
	height: 60px;
	width: 60px;
	color: white;
	margin: 5px;
	border-radius: 10px;
	opacity: 0.65;
	transition: 0.2s;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-tap-highlight-color: transparent;
}

.icon:hover {
	opacity: 1;
}

.linkedin-icon {
	font-size: 45px;
	text-decoration: none;
	background-color: rgba(0, 119, 181, 1);
}

.github-icon {
	font-size: 50px;
	text-decoration: none;
	background-color: rgba(23, 21, 21, 1);
}

.github-dark-mode {
	color: rgba(23, 21, 21, 1);
	background-color: white;
}

.github-light-mode {
	color: white;
	background-color: rgba(23, 21, 21, 1);
}

.links-container {
	margin-top: 20px;
}

.home-page-link {
	margin: 0 15px 0 15px;
}

.cv-download {
	text-decoration: none;
	display: flex;
	flex-direction: row;
	width: fit-content;
	align-self: center;
	margin-top: 20px;
}

.cv-download p {
	margin-left: 5px;
}