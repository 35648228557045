#hamburger {
	width: 30px;
	height: 25px;
	margin-bottom: 15px;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
}

#hamburger span {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: 0.25s ease-in-out;
}

#hamburger span:nth-child(1) {
	top: 0px;
}

#hamburger span:nth-child(2),#hamburger span:nth-child(3) {
	top: 10px;
}

#hamburger span:nth-child(4) {
	top: 20px;
}

#hamburger.open span:nth-child(1) {
	top: 10px;
	width: 0%;
	left: 50%;
}

#hamburger.open span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

#hamburger.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

#hamburger.open span:nth-child(4) {
	top: 10px;
	width: 0%;
	left: 50%;
}

#hamburger:hover {
	animation: shake 0.2s;
}

.dark-span {
	background: white;
}

.light-span {
	background: black;
}

@keyframes shake {
	0% { transform: rotate(0deg); }
	25% { transform: rotate(-7deg); }
	75% { transform: rotate(7deg); }
	100% { transform: rotate(0deg); }
}